import * as React from "react"

import { cva } from "class-variance-authority"

export const Stack = React.forwardRef<HTMLElement, StackProps>(
  ({ className, ...props }, ref) => {
    return <span className={classForStack({ className })} ref={ref} {...props} />
  },
)

Stack.displayName = "Stack.Stack"

export type StackProps = React.ClassAttributes<HTMLElement> &
  React.HTMLAttributes<HTMLElement>

export const classForStack = cva(["grid"])

export const Layer = React.forwardRef<HTMLElement, LayerProps>(
  ({ className, ...props }, ref) => {
    return <span className={classForLayer({ className })} ref={ref} {...props} />
  },
)

Layer.displayName = "Stack.Layer"

export type LayerProps = React.ClassAttributes<HTMLElement> &
  React.HTMLAttributes<HTMLElement>

export const classForLayer = cva(["[grid-area:1/1/-1/-1]"])
